import { ActionData } from '@/api/useAction'
import { useActionFilterSwitch } from '@/modules/actions/composable/useActionFilterSwitch'

export const showInactiveQueryKey = 'showInactive'

export const useActionFilterShowInactive = () => {
  const { modelSwitch: modelShowInactive } =
    useActionFilterSwitch(showInactiveQueryKey)

  const predicateShowInactive = (action: ActionData) => {
    return modelShowInactive.value || action.active
  }

  return {
    modelShowInactive,
    predicateShowInactive,
  }
}

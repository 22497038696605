import { useRouteQuery } from '@vueuse/router'
import { computed } from 'vue'

export const useActionFilterSwitch = (filter: string) => {
  const queryValue = useRouteQuery<'true' | undefined>(filter)

  const modelSwitch = computed({
    get: () => queryValue.value === 'true',
    set: (value) => (queryValue.value = value ? 'true' : undefined),
  })

  return {
    modelSwitch,
  }
}

import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { ActionData } from '@/api/useAction'
import {
  emptyOptionKey,
  useCompanySelect,
} from '@/modules/base/composable/useCompanySelect'

export const companyQueryKey = 'company'

export const useActionFilterCompany = () => {
  const { t } = useI18n()
  const route = useRoute()

  const {
    selectedCompanyId: modelCompany,
    options: optionsCompany,
    hasMoreThanOneCompany,
  } = useCompanySelect(() => t('actions.filterByCompany.all'))

  const predicateCompany = (action: ActionData) => {
    return (
      !route.query.company ||
      modelCompany.value === emptyOptionKey ||
      action.companyId === modelCompany.value
    )
  }

  return {
    optionsCompany,
    modelCompany,
    predicateCompany,
    hasMoreThanOneCompany,
  }
}

import { useRouteQuery } from '@vueuse/router'
import { addQuarters, addYears, getQuarter, getYear } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { ActionData, RoadmapPosition } from '@/api/useAction'

export const dateRangeQueryKey = 'dateRange'

export interface RoadmapPositionRange {
  from: RoadmapPosition
  to: RoadmapPosition
}

export enum DateRanges {
  All = 'all',
  CurrentQuarter = 'currentQuarter',
  CurrentYear = 'currentYear',
  NextQuarter = 'nextQuarter',
  NextYear = 'nextYear',
  PreviousQuarter = 'previousQuarter',
  PreviousYear = 'previousYear',
}

export const useActionFilterDateRange = () => {
  const { t } = useI18n()

  const previousYear = () => addYears(new Date(), -1)
  const nextYear = () => addYears(new Date(), +1)
  const previousQuarter = () => addQuarters(new Date(), -1)
  const nextQuarter = () => addQuarters(new Date(), +1)

  const createRoadmapPosition = (
    targetYear: number,
    targetQuarter: number
  ): RoadmapPosition => {
    return { targetYear, targetQuarter }
  }

  const createRangeQuarter = (date: Date): RoadmapPositionRange => {
    const year = getYear(date)
    const quarter = getQuarter(date)
    return {
      from: createRoadmapPosition(year, quarter),
      to: createRoadmapPosition(year, quarter),
    }
  }

  const createRangeYear = (date: Date): RoadmapPositionRange => {
    const year = getYear(date)
    return {
      from: createRoadmapPosition(year, 1),
      to: createRoadmapPosition(year, 4),
    }
  }

  const optionsDateRange = [
    {
      key: DateRanges.All,
      title: t('actions.filterByDateRange.all'),
      range: () => null,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      predicate: (_: ActionData) => true,
    },
    {
      key: DateRanges.CurrentQuarter,
      title: t('actions.filterByDateRange.currentQuarter'),
      range: () => createRangeQuarter(new Date()),
      predicate: (action: ActionData) => {
        const date = new Date()

        return (
          action.active &&
          getYear(date) === action.targetYear &&
          getQuarter(date) === action.targetQuarter
        )
      },
    },
    {
      key: DateRanges.CurrentYear,
      title: t('actions.filterByDateRange.currentYear'),
      range: () => createRangeYear(new Date()),
      predicate: (action: ActionData) =>
        action.active && getYear(new Date()) === action.targetYear,
    },
    {
      key: DateRanges.NextQuarter,
      title: t('actions.filterByDateRange.nextQuarter'),
      range: () => createRangeQuarter(nextQuarter()),
      predicate: (action: ActionData) => {
        const date = nextQuarter()

        return (
          action.active &&
          getYear(date) === action.targetYear &&
          getQuarter(date) === action.targetQuarter
        )
      },
    },
    {
      key: DateRanges.NextYear,
      title: t('actions.filterByDateRange.nextYear'),
      range: () => createRangeYear(nextYear()),
      predicate: (action: ActionData) =>
        action.active && getYear(nextYear()) === action.targetYear,
    },
    {
      key: DateRanges.PreviousQuarter,
      title: t('actions.filterByDateRange.previousQuarter'),
      range: () => createRangeQuarter(previousQuarter()),
      predicate: (action: ActionData) => {
        const date = previousQuarter()

        return (
          action.active &&
          getYear(date) === action.targetYear &&
          getQuarter(date) === action.targetQuarter
        )
      },
    },
    {
      key: DateRanges.PreviousYear,
      title: t('actions.filterByDateRange.previousYear'),
      range: () => createRangeYear(previousYear()),
      predicate: (action: ActionData) =>
        action.active && getYear(previousYear()) === action.targetYear,
    },
  ]

  const modelDateRange = useRouteQuery<DateRanges>(
    dateRangeQueryKey,
    DateRanges.All
  )

  const getEntry = (dateRange: string) =>
    optionsDateRange.find((i) => i.key === dateRange) || optionsDateRange[0]

  const getRoadmapPositionRange = (
    dateRange: string
  ): RoadmapPositionRange | null => getEntry(dateRange).range()

  const predicateDateRange = (action: ActionData): boolean =>
    getEntry(modelDateRange.value).predicate(action)

  return {
    createRoadmapPosition,
    optionsDateRange,
    modelDateRange,
    getRoadmapPositionRange,
    predicateDateRange,
  }
}
